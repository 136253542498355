@import "@scss/_shared.scss";

.btn-icon {
    i {
        font-size: 1.3rem;
    }
}
.logo-subtitle {
    position: absolute;
    bottom: 3px;
    right: 100px;
    font-family: 'Poppins';
    font-size: 210%;
    color: #ffffff;
    text-shadow: 0 0 5px black;
    font-weight: bolder;
}
.internal-container {
    @media (min-width: 992px) {
        .wrapper {
            .header {
                .header-brand {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 110px;
                        height: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        .wrapper {
            .header {
                .header-brand {
                    img {
                        width: 75px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@import "@scss/_shared.scss";

.aside-user {
  .menu {
    &.show {
      position: absolute;
      bottom: 5.8rem;
      left: 2rem;
      z-index: 105;
    }
  }
}

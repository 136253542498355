@import "@scss/_shared.scss";

.base-table {
  .th-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &:hover {
      .filter {
        opacity: 1;
      }
    }

    .filter {
      opacity: 0;
      transition: all 0.2s linear;

      &.active {
        opacity: 1;
      }

      a {
        width: 2rem;
        height: 2rem;
        border-radius: 0.65rem;
      }
    }

    .sort-icon {
      margin: 0 0.5rem;
    }
  }
}

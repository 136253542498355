@import "@scss/_shared.scss";

.base-table-user {
  width: fit-content;
  .badge {
    border-radius: 0.95rem;
    .badge-avatar {
      width: 42px;
      height: 42px;
      margin-right: 0.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.95rem;
      }
    }

    .badge-name {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }

    .badge-description {
      color: $gray-100;
    }
  }
}

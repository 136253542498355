.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fast-fade-enter-active,
.fast-fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to,
.fast-fade-enter-from,
.fast-fade-leave-to {
  opacity: 0;
}

.valign-sub {
  vertical-align: sub !important;
}

/* EXTERNAL CLASSES */

.external-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;

  .logo {
    width: 10rem;
    height: auto;
  }

  @media (min-width: 992px) {
    .d-mobile-block {
      display: none !important;
    }

    .right-content {
      display: block !important;
      width: 35rem;
    }

    .left-content {
      width: calc(100vw - 35rem);
    }

    .d-mobile-none {
      display: block !important;
    }
  }
  @media (max-width: 991.98px) {
    .d-mobile-none {
      display: none !important;
    }

    .right-content {
      display: none !important;
    }

    .left-content {
      width: 100%;
    }

    .d-mobile-block {
      display: block !important;
    }
  }

  .right-content {
    height: 100vh;
    position: relative;
    display: flex;
    background-color: $primary;

    .absolute-content {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .external-image-overlayed {
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgb(79 201 218) 35%,
          rgb(79 201 218 / 2%) 100%
        );
      }
    }
  }

  .left-content {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

/* INTERNAL CLASSES */

/* GENERAL CLASSES */

.font-12 {
  font-size: 0.6em !important;
  line-height: 1.1;
}
.font-14 {
  font-size: 0.85em !important;
  line-height: 1.5;
}
.font-16 {
  font-size: 1em !important;
  line-height: 1.1;
}
.font-18 {
  font-size: 1.1em !important;
  line-height: 1.3;
}
.font-20 {
  font-size: 1.2em !important;
  line-height: 1.3;
}
.font-24 {
  font-size: 1.5em !important;
  line-height: 1.3;
}
.font-32 {
  font-size: 2em !important;
  line-height: 1.1;
}
.font-40 {
  font-size: 2.5em !important;
  line-height: 1.3;
}
.font-48 {
  font-size: 3em !important;
  line-height: 1.1;
}
.font-56 {
  font-size: 3.5em !important;
  line-height: 1.1;
}
.font-64 {
  font-size: 4em !important;
  line-height: 1.1;
}
.font-72 {
  font-size: 4.5em !important;
  line-height: 1.1;
}
.font-96 {
  font-size: 6em !important;
  line-height: 1.1;
}
.font-112 {
  font-size: 7em !important;
  line-height: 1.1;
}
.font-128 {
  font-size: 8em !important;
  line-height: 1.1;
}
.font-144 {
  font-size: 9em !important;
  line-height: 1.1;
}
.font-160 {
  font-size: 10em !important;
  line-height: 1.1;
}
.font-200 {
  font-size: 18em !important;
  line-height: 1.1;
}

@media only screen and (min-width: 576px) {
  .font-sm-14 {
    font-size: 0.85em !important;
    line-height: 1.5;
  }
  .font-sm-16 {
    font-size: 1em !important;
    line-height: 1.1;
  }
  .font-sm-18 {
    font-size: 1.1em !important;
    line-height: 1.3;
  }
  .font-sm-20 {
    font-size: 1.2em !important;
    line-height: 1.3;
  }
  .font-sm-24 {
    font-size: 1.5em !important;
    line-height: 1.3;
  }
  .font-sm-32 {
    font-size: 2em !important;
    line-height: 1.1;
  }
  .font-sm-40 {
    font-size: 2.5em !important;
    line-height: 1.3;
  }
  .font-sm-48 {
    font-size: 3em !important;
    line-height: 1.1;
  }
  .font-sm-56 {
    font-size: 3.5em !important;
    line-height: 1.1;
  }
  .font-sm-64 {
    font-size: 4em !important;
    line-height: 1.1;
  }
  .font-sm-72 {
    font-size: 4.5em !important;
    line-height: 1.1;
  }
  .font-sm-96 {
    font-size: 6em !important;
    line-height: 1.1;
  }
  .font-sm-112 {
    font-size: 7em !important;
    line-height: 1.1;
  }
  .font-sm-128 {
    font-size: 8em !important;
    line-height: 1.1;
  }
  .font-sm-144 {
    font-size: 9em !important;
    line-height: 1.1;
  }
  .font-sm-160 {
    font-size: 10em !important;
    line-height: 1.1;
  }
  .font-sm-200 {
    font-size: 18em !important;
    line-height: 1.1;
  }
}

@media only screen and (min-width: 768px) {
  .font-md-14 {
    font-size: 0.85em !important;
    line-height: 1.5;
  }
  .font-md-16 {
    font-size: 1em !important;
    line-height: 1.1;
  }
  .font-md-18 {
    font-size: 1.1em !important;
    line-height: 1.3;
  }
  .font-md-20 {
    font-size: 1.2em !important;
    line-height: 1.3;
  }
  .font-md-24 {
    font-size: 1.5em !important;
    line-height: 1.3;
  }
  .font-md-32 {
    font-size: 2em !important;
    line-height: 1.1;
  }
  .font-md-40 {
    font-size: 2.5em !important;
    line-height: 1.3;
  }
  .font-md-48 {
    font-size: 3em !important;
    line-height: 1.1;
  }
  .font-md-56 {
    font-size: 3.5em !important;
    line-height: 1.1;
  }
  .font-md-64 {
    font-size: 4em !important;
    line-height: 1.1;
  }
  .font-md-72 {
    font-size: 4.5em !important;
    line-height: 1.1;
  }
  .font-md-96 {
    font-size: 6em !important;
    line-height: 1.1;
  }
  .font-md-112 {
    font-size: 7em !important;
    line-height: 1.1;
  }
  .font-md-128 {
    font-size: 8em !important;
    line-height: 1.1;
  }
  .font-md-144 {
    font-size: 9em !important;
    line-height: 1.1;
  }
  .font-md-160 {
    font-size: 10em !important;
    line-height: 1.1;
  }
  .font-md-200 {
    font-size: 18em !important;
    line-height: 1.1;
  }
}

@media only screen and (min-width: 992px) {
  .font-lg-14 {
    font-size: 0.85em !important;
    line-height: 1.5;
  }
  .font-lg-16 {
    font-size: 1em !important;
    line-height: 1.1;
  }
  .font-lg-18 {
    font-size: 1.1em !important;
    line-height: 1.3;
  }
  .font-lg-20 {
    font-size: 1.2em !important;
    line-height: 1.3;
  }
  .font-lg-24 {
    font-size: 1.5em !important;
    line-height: 1.3;
  }
  .font-lg-32 {
    font-size: 2em !important;
    line-height: 1.1;
  }
  .font-lg-40 {
    font-size: 2.5em !important;
    line-height: 1.3;
  }
  .font-lg-48 {
    font-size: 3em !important;
    line-height: 1.1;
  }
  .font-lg-56 {
    font-size: 3.5em !important;
    line-height: 1.1;
  }
  .font-lg-64 {
    font-size: 4em !important;
    line-height: 1.1;
  }
  .font-lg-72 {
    font-size: 4.5em !important;
    line-height: 1.1;
  }
  .font-lg-96 {
    font-size: 6em !important;
    line-height: 1.1;
  }
  .font-lg-112 {
    font-size: 7em !important;
    line-height: 1.1;
  }
  .font-lg-128 {
    font-size: 8em !important;
    line-height: 1.1;
  }
  .font-lg-144 {
    font-size: 9em !important;
    line-height: 1.1;
  }
  .font-lg-160 {
    font-size: 10em !important;
    line-height: 1.1;
  }
  .font-lg-200 {
    font-size: 18em !important;
    line-height: 1.1;
  }
}

@media only screen and (min-width: 1200px) {
  .font-xl-14 {
    font-size: 0.85em !important;
    line-height: 1.5;
  }
  .font-xl-16 {
    font-size: 1em !important;
    line-height: 1.1;
  }
  .font-xl-18 {
    font-size: 1.1em !important;
    line-height: 1.3;
  }
  .font-xl-20 {
    font-size: 1.2em !important;
    line-height: 1.3;
  }
  .font-xl-24 {
    font-size: 1.5em !important;
    line-height: 1.3;
  }
  .font-xl-32 {
    font-size: 2em !important;
    line-height: 1.1;
  }
  .font-xl-40 {
    font-size: 2.5em !important;
    line-height: 1.3;
  }
  .font-xl-48 {
    font-size: 3em !important;
    line-height: 1.1;
  }
  .font-xl-56 {
    font-size: 3.5em !important;
    line-height: 1.1;
  }
  .font-xl-64 {
    font-size: 4em !important;
    line-height: 1.1;
  }
  .font-xl-72 {
    font-size: 4.5em !important;
    line-height: 1.1;
  }
  .font-xl-96 {
    font-size: 6em !important;
    line-height: 1.1;
  }
  .font-xl-112 {
    font-size: 7em !important;
    line-height: 1.1;
  }
  .font-xl-128 {
    font-size: 8em !important;
    line-height: 1.1;
  }
  .font-xl-144 {
    font-size: 9em !important;
    line-height: 1.1;
  }
  .font-xl-160 {
    font-size: 10em !important;
    line-height: 1.1;
  }
  .font-xl-200 {
    font-size: 18em !important;
    line-height: 1.1;
  }
}

.max-auto {
  max-width: none;
}
.max-50 {
  max-width: 4.2rem;
}
.max-100 {
  max-width: 6.25rem;
}
.max-150 {
  max-width: 9.375rem;
}
.max-200 {
  max-width: 14.5rem;
}
.max-250 {
  max-width: 15.625rem;
}
.max-280 {
  max-width: 17.8rem;
}
.max-300 {
  max-width: 18.75rem;
}
.max-350 {
  max-width: 21.875rem;
}
.max-400 {
  max-width: 25rem;
}
.max-450 {
  max-width: 28.125rem;
}
.max-500 {
  max-width: 31.25rem;
}
.max-550 {
  max-width: 34.375rem;
}
.max-600 {
  max-width: 37.5rem;
}
.max-650 {
  max-width: 40.625rem;
}
.max-700 {
  max-width: 46.25rem;
}
.max-750 {
  max-width: 52.25rem;
}

$font-family-sans-serif: 'Poppins', Helvetica, sans-serif;

$body-bg: #fff;
$body-color: #211f1c;

//COLORS
$light-primary: #ddf8fc;
$active-primary: #30a6b6;
$light-success: #f4fbdb;
$active-success: #9fbe22;
$light-info: #eff0ff;
$active-info: #383ebc;
$light-warning: #fdf4d6;
$active-warning: #cfab2a;
$light-danger: #fff0ed;
$active-danger: #db5437;
$light-dark: #f6f1e9;
$active-dark: #191715;
$blue: #009ef6;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #ffffff;
$light: #f8f6f2;
$primary: #4fc9da;
$secondary: #dad3c3;
$success: #b8d935;
$info: #4f55da;
$warning: #e8c444;
$danger: #f06445;
$dark: #211f1c;
$sand: #f8f6f2;
$gray: #8e887a;
$gray-100: #f8f6f2;
$gray-200: #f6f1e9;
$gray-300: #dad3c3;
$gray-400: #b5b0a1;
$gray-500: #968e7e;
$gray-600: #8e887a;
$gray-700: #716d66;
$gray-800: #403d38;
$gray-900: #211f1c;

$sand-border: #e0e0de;
$sand-2: #f0e8d9;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'sand': $sand,
  'gray': $gray-600,
  'gray-dark': $gray-800,
);
